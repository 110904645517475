<template>

    <div id="crud">
      <h1>{{$t("Default: only data")}}</h1>
      <form v-on:submit.prevent="submitForm">
        <div class="form-group">
          <label for="handle">Handle</label>
          <input type="text" class="form-control" id="handle" placeholder="format 2117_12345" v-model="form.handle">
        </div>
        <div class="row form-group">
          <div class="col-4">
            <label for="year">Year</label>
            <input type="text" class="form-control" id="year" placeholder="insert year" v-model="form.year">
          </div>
          <div class="col-4">
            <label for="volume">Volume</label>
            <input type="text" class="form-control" id="volume" placeholder="insert volume" v-model="form.volume">
          </div>
          <div class="col-4">
            <label for="number">Number</label>
            <input type="text" class="form-control" id="number" placeholder="insert number" v-model="form.number">
          </div>
        </div>


        <div class="form-group">
          <button class="btn btn-success">Submit</button>
        </div>
      </form>


      <div class="card text-white bg-secondary mb-3">
        <div class="card-header">XML</div>
        <div class="card-body">
          <div class="card-text">
            <pre lang="xml" class="text-white">{{ response }}</pre>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
  import axios from 'axios'
    export default {
        name: 'OjsImport',
        data(){
          return{
            form: {
              handle: '',
              year: '',
              volume: '',
              number: ''
              /*profession: [],
              satisfaction: '5',
              interested: [],
              terms: false*/
            },
            response: '',
            errors: []
          }
        },
        methods:{

          submitForm(){

            let params = ""

            if(this.form.year != "") params += "&year=" + this.form.year
            if(this.form.volume != "") params += "&volume=" + this.form.volume
            if(this.form.number != "") params += "&number=" + this.form.number



            axios.get('http://localhost:7007/dspaceojs/xml/'+this.form.handle+"?"+params)
                .then((response) => {
                    let init = response.data.indexOf("<!--")
                    let end = response.data.indexOf("-->")
                    this.response = response.data.substring(init+5, end)

                    var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                    var fileLink = document.createElement('a');

                    fileLink.href = fileURL;
                    fileLink.setAttribute('download', 'file.xml');
                    document.body.appendChild(fileLink);

                    fileLink.click();




                })
                .catch((error) => {
                    this.response = ""
                    this.errors.push(error)
                }).finally(() => {
              //Perform action in always
            })
          }
        }
    }
</script>
