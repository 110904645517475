<template>

    <div id="crud">
      <h1>{{$t("Generate native XML import")}}</h1>
      <form v-on:submit.prevent="submitForm">
        <div class="form-group required">
          <label for="handle" class="control-label">Handle</label>
          <input type="text" class="form-control" id="handle" placeholder="format 2117_12345" v-model="form.handle">
          <!-- Error Message -->
          <div class="input-errors" v-for="(error, index) of v$.form.handle.$errors" :key="index">
            <div class="error-msg text-danger">{{ error.$message }}</div>
          </div>

          <!--div v-if="v$.form.handle.$error" class="alert alert-danger mt-3">
            {{ v$.form.handle.$errors[0].$message }}
            <p v-if="!v$.form.handle.required.$response">Handle is required.</p>
            <p v-if="!v$.form.handle.minLength.$response">Handle field must have at least {{ v$.form.handle.minLength.$params.min }} characters.</p>
          </div-->

        </div>
        <div class="row form-group">
          <div class="col-4">
            <label for="year">Year</label>
            <input type="text" class="form-control" id="year" placeholder="insert year" v-model="form.year">
          </div>
          <div class="col-4">
            <label for="volume">Volume</label>
            <input type="text" class="form-control" id="volume" placeholder="insert volume" v-model="form.volume">
          </div>
          <div class="col-4">
            <label for="number">Number</label>
            <input type="text" class="form-control" id="number" placeholder="insert number" v-model="form.number">
          </div>
        </div>


        <div class="form-group">
          <button class="btn btn-success">Submit</button>
        </div>
      </form>



      <div v-if="response != '' && response != 'error'" class="card text-white bg-success mb-3">
        <div class="card-header">Import the XML into OJS (import native XML), and execute this queries in OJS database</div>
        <div class="card-body">
          <div class="card-text">
            <pre lang="xml" class="text-white">{{ response }}</pre>
          </div>
        </div>
      </div>

      <div v-if="response == 'error'" class="card text-white bg-danger mb-3">
        <div class="card-header">No data!</div>
        <div class="card-body">
          <div class="card-text" >
            {{errors}}
          </div>
        </div>
      </div>
    </div>

</template>

<script>
    import axios from 'axios'
    import useValidate from "@vuelidate/core";
    import { required, minLength } from '@vuelidate/validators'


    export default {
        name: 'OjsImport',


        data(){
          return{
            v$: useValidate(),
            form: {
              handle: '',
              year: '',
              volume: '',
              number: ''
              /*profession: [],
              satisfaction: '5',
              interested: [],
              terms: false*/
            },
            response: '',
            errorsForm: false,
            errors: []
          }
        },
        validations:{
          form: {
            handle: {
              required,
              minLength: minLength(8)
            },
          }
        },
        methods:{

          submitForm(){

            console.log(process.env)

            this.v$.$validate() // checks all inputs
            if (!this.v$.$error) { // if ANY fail validation
              //alert('Form successfully submitted.')
            } else {
              //alert('Form failed validation')
              return
            }

            let params = ""

            if(this.form.year != "") params += "&year=" + this.form.year
            if(this.form.volume != "") params += "&volume=" + this.form.volume
            if(this.form.number != "") params += "&number=" + this.form.number



            //axios.get('http://localhost:7007/dspaceojs/xml/'+this.form.handle+"?"+params)
            axios.get('https://idpadmin.upc.edu/api/dspaceojs/xml/'+this.form.handle+"?"+params)
                .then((response) => {
                    let init = response.data.indexOf("<!--")
                    let end = response.data.indexOf("-->")
                    this.response = response.data.substring(init+5, end)

                    var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                    var fileLink = document.createElement('a');

                    fileLink.href = fileURL;
                    fileLink.setAttribute('download', 'file.xml');
                    document.body.appendChild(fileLink);

                    fileLink.click();




                })
                .catch((error) => {
                    this.response = "error"
                    this.errors = error
                }).finally(() => {
              //Perform action in always
            })
          }
        }
    }
</script>


<style scoped>

.form-group.required .control-label:after {
  content:" *";
  color:red;
}

</style>


