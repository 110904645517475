import {translations as templateTranslations, appLanguage} from '@f1/upc-webapp-ui-vue3'
import caJson from '../translations/ca.json'
import enJson from '../translations/en.json'
import esJson from '../translations/es.json'
import {createI18n} from 'vue-i18n'
let appTranslations = {
    ca: caJson,
    en: enJson,
    es: esJson,
}

let allTranslations = {}
Object.entries(templateTranslations).forEach( ([key, value]) => {
    allTranslations[key] = {...value, ...appTranslations[key]}
})

const i18n = createI18n({
    locale: appLanguage,
    fallbackLocale: 'ca',
    messages: allTranslations,
})

export {
    i18n,
}
