import {createOidcAuth, LogLevel, SignInType} from 'vue-oidc-client/vue3'
import store from "@/store"

let mainOidc = null

if (window.auth) {
    const loco = window.location
    const appRootUrl = `${loco.protocol}//${loco.host}${process.env.BASE_URL}`;
    mainOidc = createOidcAuth(
        'main',
        SignInType.Window,
        appRootUrl,
        window.auth,
        console,
        LogLevel.Error
    )
    // eslint-disable-next-line no-unused-vars
    mainOidc.events.addUserLoaded(function(user) {
        store.commit('logged', true)
    })

}

export default mainOidc
