import {createApp} from 'vue'
import App from './App.vue'
import router from './router.js'
import store from './store.js'
import {mixin, elementPlusLocale} from '@f1/upc-webapp-ui-vue3'
import elementplus from 'element-plus'
import {i18n} from './i18n.js'
import mainAuth from './auth'

import { Vuelidate } from '@vuelidate/core'

const init = () => {
    const app = createApp(App)

    app.use(elementplus, {locale: elementPlusLocale})
    app.use(i18n)
    app.mixin({...mixin})
    app.use(Vuelidate)

    app.use(router)
    app.use(store)
    app.mount('#app')
    return app
}

if (window.auth) {
    mainAuth.useRouter(router)
    mainAuth.startup().then(success => {
        if (success) {
            const app = init()
            app.config.globalProperties.$oidc = mainAuth
        } else {
            console.log('Startup was not ok')
        }
    })
} else {
    init()
}

