<template>
    <Main
        :itemsMenuNavegacio="itemsMenuNavegacio"
        :titolApp="titolApp"
    ></Main>
</template>

<script>

import 'element-plus/theme-chalk/index.css'
import {Main} from '@f1/upc-webapp-ui-vue3'

export default {
    name: 'App',
    components: {
        Main
    },
    data() {
        return {}
    },
    computed: {
        titolApp() {
            return this.$t("IDPadmin")
        },
        itemsMenuNavegacio() {
            return [
                {
                    to: '/',
                    icon: 'home',
                    text: this.$t('Inici')
                },
                {
                    tos: [
                        {to: '/pagina-publica', text: this.$t('Pàgina pública')},
                        {to: '/pagina-privada', text: this.$t('Pàgina privada')},
                        {to: '/ojs-import/index', text: this.$t('OJS xml import')},
                        //{to: '/pagina-inexistent', text: this.$t('Pàgina inexistent')},
                    ],
                    icon: 'format-list-bulleted',
                    text: this.$t('OJS Import')
                },
              {
                tos: [
                  {to: '/crossref/index', text: this.$t('CrossRef')},
                ],
                icon: 'adjust',
                text: this.$t('Crossref')
              },
                {
                    tos: [
                        {to: '/crud/default', text: this.$t('Default')},
                        {to: '/crud/filter-and-default-sort', text: this.$t('Filter and default sort')},
                        {to: '/crud/pagination-and-slot', text: this.$t('Pagination and slot')},
                        {to: '/crud/full', text: this.$t('Full')},
                    ],
                    icon: 'table',
                    text: this.$t('CRUD')
                },
            ]
        }
    },
    created() {
        this.$store.commit('logged', true)
        this.showLoading(this.$t('Loading sample'), 1000)
    }
}

</script>
